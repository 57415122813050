import { createStyles } from "@material-ui/core/styles";
import { successColor, theme, warningColor } from "../../styles/mui/theme";

export const muiStylesSendResetEmail = () =>
  createStyles({
    root: {
      alignSelf: "center",
      position: "absolute",
      marginTop: "7px",
      [theme.breakpoints.up("sm")]: {
        alignSelf: "flex-end",
        marginRight: 70,
      },
    },
    icons: {
      fontSize: "10px",
      marginRight: "5px",
    },
    success: {
      color: successColor,
    },
    error: {
      color: warningColor,
    },
  });
