import * as styles from "../reset-password.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPasswordResetInProgress,
  selectPasswordResetSucceeded,
} from "../../../redux/selectors/passwordSelectors";
import {
  styleFormControlLabel,
  styleTextField,
} from "../../../styles/mui/styleFormControlLabel";
import { muiStylesSendResetEmail } from "../muiStylesSendResetEmail";
import CircularProgress from "@material-ui/core/CircularProgress";
import { resetPasswordConfirm } from "../../../redux/actions/passwordResetActions";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ValidationState from "../../ValidationState";

const useStyles = makeStyles(styleFormControlLabel);
const useTextFieldStyles = makeStyles(styleTextField);
const useResetPasswordStyles = makeStyles(muiStylesSendResetEmail);

const ResetPassword: FC<{ token: string }> = ({ token }) => {
  const classes = useStyles();
  const resetPasswordClasses = useResetPasswordStyles();
  const textFieldClasses = useTextFieldStyles();

  const dispatch = useDispatch();
  const requestPending = useSelector(selectPasswordResetInProgress);
  const requestSucceeded = useSelector(selectPasswordResetSucceeded);
  const [requestSucceededState, setRequestSucceededState] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const mounted = useRef(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<{ newPassword: string }>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    criteriaMode: "all",
  });

  // parse Redux state and extract reset_password/confirm request state on componentDidUpdate.
  // Logic implemented to prevent initial render of the snackbar and disabling inputs on componentDidMount,
  // after submitting the form before.
  useEffect(() => {
    if (!mounted.current) {
      // componentDidMount logic
      mounted.current = true;
      setError("newPassword", { types: { min: "min", matches: "matches" } });
    } else if (requestSucceeded) {
      // componentDidUpdate logic for requestSucceeded:
      setRequestSucceededState(true);
    }
  }, [requestSucceeded, setError]);

  const onSubmit = handleSubmit((data): void => {
    dispatch(resetPasswordConfirm({ ...data, token }));
  });

  return (
    <div className={styles.container}>
      <Link
        className={`${styles.description} ${styles.smallFont} ${styles.noHrefA}`}
        to="/"
      >
        Back to Log in
      </Link>
      <h3 className={styles.headerPosition}>Reset Your Password</h3>
      <p className={styles.description}>
        Your password must be at least 8 characters long and contain at least
        one number
      </p>
      <form
        onSubmit={onSubmit}
        className={`${styles.loginForm} ${styles.formMargin}`}
      >
        {/* for some mystical reason the div below is required for the form validation to work properly in production build... */}
        <div />
        <FormControlLabel
          control={
            <TextField
              {...register("newPassword")}
              type={showPassword ? "text" : "password"}
              error={!!errors.newPassword}
              variant="filled"
              fullWidth
              disabled={requestPending || requestSucceededState}
              InputProps={{
                className: textFieldClasses.textField,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
          classes={classes}
          labelPlacement="top"
          label="PASSWORD"
        />
        <p
          className={`${styles.description} ${styles.smallFont} ${styles.marginBottom0}`}
        >
          <ValidationState
            error={"min" in (errors?.newPassword?.types || {})}
          />
          8 characters or more
        </p>
        <p className={`${styles.description} ${styles.smallFont}`}>
          <ValidationState
            error={"matches" in (errors?.newPassword?.types || {})}
          />
          One Number and One Letter
        </p>
        <div className={styles.formButtonContainer}>
          <Button
            className={styles.button}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || requestPending || requestSucceededState}
          >
            Reset password
          </Button>
          {requestPending && (
            <CircularProgress classes={resetPasswordClasses} size={24} />
          )}
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
