import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "gatsby";
import { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSendEmail } from "../../../redux/actions/passwordResetActions";
import {
  selectEmailSendingInProgress,
  selectEmailSendingSucceeded,
} from "../../../redux/selectors/passwordSelectors";
import {
  styleFormControlLabel,
  styleTextField,
} from "../../../styles/mui/styleFormControlLabel";
import { muiStylesSendResetEmail } from "../muiStylesSendResetEmail";
import * as styles from "../reset-password.module.scss";
import { schema } from "./schemas";

const useStyles = makeStyles(styleFormControlLabel);
const useTextFieldStyles = makeStyles(styleTextField);
const useLoaderStyles = makeStyles(muiStylesSendResetEmail);

const SendResetEmailForm: FC = () => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();
  const textFieldClasses = useTextFieldStyles();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const requestPending = useSelector(selectEmailSendingInProgress);
  const requestSucceeded = useSelector(selectEmailSendingSucceeded);
  const [requestSucceededState, setRequestSucceededState] = useState(false);
  const mounted = useRef(false);

  // parse Redux state and extract email sending request state on componentDidUpdate. Logic implemented
  // to prevent initial disabling of inputs on componentDidMount, after submitting the form before.
  useEffect(() => {
    if (!mounted.current) {
      // componentDidMount logic
      mounted.current = true;
    } else if (requestSucceeded) {
      // componentDidUpdate logic for requestSucceeded:
      setRequestSucceededState(true);
    }
  }, [requestSucceeded]);

  const onSubmit = handleSubmit((data): void => {
    dispatch(resetPasswordSendEmail(data));
  });

  return (
    <div className={styles.container}>
      <Link
        className={`${styles.description} ${styles.smallFont} ${styles.noHrefA}`}
        to="/"
      >
        Back to Log in
      </Link>
      <h3 className={styles.headerPosition}>
        {requestSucceededState ? "Reset Email Sent!" : "Forgot Password?"}
      </h3>
      <p className={styles.description}>
        {requestSucceededState
          ? "If you don't see any email make sure to check your spam folder. If you can't find it, try sending again."
          : "Mistakes happen! We'll send you an email with a link to reset your password."}
      </p>
      {requestSucceededState ? null : (
        <p className={styles.description}>
          For the first time users, please email{" "}
          <a href="mailto:motko@motko.co">motko@motko.co</a> to get your account
          activated.
        </p>
      )}
      <form
        onSubmit={onSubmit}
        className={`${styles.loginForm} ${styles.formMargin}`}
      >
        <FormControlLabel
          control={
            <TextField
              {...register("email")}
              type="text"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="filled"
              InputProps={{ className: textFieldClasses.textField }}
              fullWidth
              disabled={requestPending || requestSucceededState}
            />
          }
          classes={classes}
          labelPlacement="top"
          label="EMAIL"
        />
        <div className={styles.formButtonContainer}>
          <Button
            className={styles.button}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || requestPending || requestSucceededState}
          >
            Reset password
          </Button>
          {requestPending && (
            <CircularProgress classes={loaderClasses} size={24} />
          )}
        </div>
      </form>
    </div>
  );
};

export default SendResetEmailForm;
