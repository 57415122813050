import SendResetEmail from "../components/ResetPassword/SendResetEmail";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import OnboardLayout from "../components/Layout/OnboardLayout";
import React, { FC } from "react";
import { PageProps } from "gatsby";
import * as queryString from "query-string";
import { theme } from "../styles/mui/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Notifier from "../components/Snackbar/Notifier";
import AppSnackbarProvider from "../components/Snackbar/SnackbarProvider";

const ResetPasswordPage: FC<PageProps> = ({ location }) => {
  const { token } = queryString.parse(location.search);

  return (
    <ThemeProvider theme={theme}>
      <AppSnackbarProvider maxSnackbars={1}>
        <Notifier />
        <OnboardLayout>
          {token && !Array.isArray(token) ? (
            <ResetPassword token={token} />
          ) : (
            <SendResetEmail />
          )}
        </OnboardLayout>
      </AppSnackbarProvider>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;
